/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
}

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #c47847; font-weight: 700; }

.bgcorange, .bgcorange_h:hover { background-color: #c47847; }
.bgcorange_i, .bgcorange_h_i:hover { background-color: #c47847 !important; }
.bgcdblue, .bgcdblue_h:hover { background-color: #16232c; }
.bgcdblue_i, .bgcdblue_h_i:hover { background-color: #16232c !important; }

.corange, .corange_h:hover { color: #c47847; }
.corange_i, .corange_h_i:hover { color: #c47847 !important; }
.cdblue, .cdblue_h:hover { color: #16232c; }
.cdblue_i, .cdblue_h_i:hover { color: #16232c !important; }

.brcorange, .brcorange_h:hover { border-color: #c47847; }
.brcorange_i, .brcorange_h_i:hover { border-color: #c47847 !important; }
.brcdblue, .brcdblue_h:hover { border-color: #16232c; }
.brcdblue_i, .brcdblue_h_i:hover { border-color: #16232c !important; }

p { margin-bottom: 1.5em; line-height: 1.4; }
p + p { margin-top: -0.5em; }

.link[href] {
  color: #c47847;
  text-decoration: underline;
  cursor: pointer;
}
  .link[href]:hover {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  border: 5px solid #c47847;
  padding: 1.875em;
}
  .form-popup-container .form-block-container {
    width: 440px;
  }

  .form-block-container .subsubheader {
    font-size: 1.6em;
    padding-top: 0px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 1.5625em;
    }
      .form-label {
        font-size: 1.125em;
        font-weight: 600;
        margin-bottom: 0.625rem;
      }

      .input-container {
        background-color: #f0f0f0;
        border: none;
        border-radius: 0.3125em;
        padding: 0.375em 0.5em;
        position: relative;
        z-index: 1;
      }
        .input-container input,
        .input-container textarea,
        .input-container select {
          background: transparent;
          font-size: 1.125em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }
          /*.input-container input[placeholder] { color: #555; opacity: 1; }
          .input-container input::-webkit-input-placeholder { color: #555; opacity: 1; }
          .input-container input::-ms-input-placeholder { color: #555; opacity: 1; }
          .input-container input:-ms-input-placeholder { color: #555; opacity: 1; }
          .input-container input::-moz-placeholder { color: #555; opacity: 1; }
          .input-container input:-moz-placeholder { color: #555; opacity: 1; }
          .input-container input:placeholder { color: #555; opacity: 1; }*/

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #c47847;
          border-radius: 4px;
          font-size: 0.8125em;
          line-height: 1.3;
          padding: 2px 5px 3px;
          margin: 2px 0px 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      margin: 12px 0px 0px -13px;
    }
      .form-button {
        padding: 13px 0px 0px 13px;
      }



.button-style {
  display: inline-block;
  color: #fff;
  background: #e3925e;
	background: -moz-linear-gradient(top, #ee9d69, #bc6a36);
	background: -webkit-gradient(linear, left top, left bottom,color-stop(0%,#ee9d69),color-stop(100%,#bc6a36));
	background: -webkit-linear-gradient(top, #ee9d69, #bc6a36);
	background: -o-linear-gradient(top, #ee9d69, #bc6a36);
	background: -ms-linear-gradient(top, #ee9d69, #bc6a36);
	background: linear-gradient(top, #ee9d69, #bc6a36);
  border: none;
  border-radius: 3px;
  box-shadow: 0em 0em 1.5em -0.15em #c47847;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  padding: 1.1em 1.6em;
  cursor: pointer;
  position: relative;
}
  .button-style[disabled],
  .button-style:disabled,
  .button-style.disabled {
    color: #333 !important;
    background: #aaa !important;
    cursor: default;
  }


  .button-style > * {
    display: block;
    text-transform: uppercase;
  }


ul.ul, ol.ol {
  margin-top: -0.5em;
  margin-bottom: 1.5625em;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  ul.ul li, ol.ol li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 0.5em;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before {
      content: "●";
      display: inline-block;
      background: url('/i/ul-list-style-image.svg') no-repeat 50% 50% transparent;
      background-size: contain;
      margin-right: 0.6em;
      width: 1em;
      min-width: 1em;
      color: transparent;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 600;
      margin-right: 0.6em;
      color: #e3925e;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:after {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 100% 100% no-repeat transparent;
    background-size: contain;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 10%;
    height: 10%;
    opacity: 0.8;
    z-index: 3;

    -webkit-transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
    -moz-transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
    -o-transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
    transition: width .3s ease-in-out 0s, height .3s ease-in-out 0s, opacity .3s ease-in-out 0s;
  }
    a.zoom-image:hover:after {
      width: 15%;
      height: 15%;
      opacity: 1 !important;
    }


.unwrapper {
  margin-left: -9999px;
  margin-right: -9999px;
  position: static;
}
.wrapper {
  width: 1250px;
  position: relative;
  margin: 0px auto;
  padding: 0px;
}
  .main-rotator-container + .wrapper {
    z-index: 2;
  }



/* Structure */
.structure {
  overflow: hidden;
}

  /* Header */
  .header-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: stretch;
  }
    .header {
      position: relative;
      z-index: 5;
    }
      .header-info-menu-container {
        background: #f0f0f0;
      }
        .header-menu-button-container,
        .header-menu-checkbox {
          display: none;
        }

        .header-info-menu > li > ul {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: stretch;
          position: relative;
          z-index: 1;
        }
          .header-info-menu li {
            position: relative;
          }
            .header-info-menu .dropmarker {
              display: none;
            }

            .header-info-menu li > div {
              position: relative;
            }
              .header-info-menu li > div a {
                cursor: pointer;
              }
                .header-info-menu li.active > div a {
                  cursor: default;
                }

            .header-info-menu > li > div {
              display: none;
            }

              .header-info-menu li > div > div > a,
              .header-info-menu li > div > div > span {
                font-size: 1.125em;
                display: block;
                white-space: nowrap;
                text-decoration: none;
              }
                .header-info-menu > li > ul > li > div > div > a,
                .header-info-menu > li > ul > li > div > div > span {
                  font-weight: 600;
                  padding: 1em 0em;
                  margin-left: 1.7777em;
                  text-align: center;
                }

                .header-info-menu li > div > div > a:hover {
                  text-decoration: underline;
                }
                .header-info-menu li.active > div > div > a {
                  color: #c47847;
                  text-decoration: underline;
                }


            .header-info-menu > li > ul > li.with-dropdown > div:after {
              content: "";
              background: #c47847;
              display: block;
              position: absolute;
              bottom: -4px;
              left: 1.7777em;
              right: 0px;
              height: 4px;

              visibility: hidden;
              opacity: 0;

              -webkit-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -moz-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -o-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              transition: opacity .3s ease 0s, visibility 0s ease .3s;
            }
              .header-info-menu > li > ul > li.with-dropdown:hover > div:after {
                visibility: visible;
                opacity: 1;

                -webkit-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -moz-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -o-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                transition: opacity .3s ease 0s, visibility 0s ease 0s;
              }

            .header-info-menu > li > ul > li > ul {
              background: #fff;
              border: 4px solid #c47847;
              position: absolute;
              top: 100%;
              right: 0px;
              min-width: 100%;
              padding: 0.625em 0.9375em;
              margin-top: 4px;

              visibility: hidden;
              opacity: 0;

              -webkit-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -moz-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -o-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              transition: opacity .3s ease 0s, visibility 0s ease .3s;
            }
              .header-info-menu > li > ul > li:hover > ul {
                visibility: visible;
                opacity: 1;

                -webkit-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -moz-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -o-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                transition: opacity .3s ease 0s, visibility 0s ease 0s;
              }

              .header-info-menu > li > ul > li > ul > li > div {
                padding: 0.1875em 0em 0em;
              }
                .header-info-menu > li > ul > li > ul > li > div > div > a {
                  font-weight: 400;
                }
                  .header-info-menu > li li > div > div > * > span {
                    text-decoration: none;
                  }
                    .header-info-menu > li li > div > div > a:hover > span {
                      color: #c47847;
                      text-decoration: underline;
                    }
                    .header-info-menu > li li.active > div > div > a > span {
                      color: #c47847;
                      font-weight: 600;
                      text-decoration: none;
                    }


      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding: 0.875em 0em;
        margin-left: -45px;
      }
        .header-content > div {
          padding-left: 45px;
        }
          .header-logo-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
            .header-slogan {
              font-size: 0.875em;
              font-weight: 600;
              padding-left: 1.4285em;
            }

          .header-contact {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: stretch;
          }
            .header-contact > div + div {
              padding-left: 45px;
            }

            .header-contact-left {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: stretch;
              padding: 1.5em 0em;
            }
              .header-address-container,
              .header-emails-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 1.25em;
                font-weight: 600;
              }
                .header-address-container:before,
                .header-emails-container:before {
                  content: "";
                  background: url('/i/header-address-icon.svg') no-repeat 0% 0% transparent;
                  width: 1.25em;
                  height: 100%;
                  min-height: 1.55em;
                  margin-right: 1em;
                }
                .header-emails-container:before {
                  background-image: url('/i/header-emails-icon.svg');
                }

                .header-emails a {
                  cursor: pointer;
                  text-decoration: underline;
                  white-space: nowrap;
                }
                  .header-emails a:hover {
                    text-decoration: none;
                  }


            .header-phones-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
            }
              .header-phones-container:before {
                content: "";
                background: url('/i/header-phones-icon.svg') no-repeat 0% 0% transparent;
                width: 1.875em;
                height: 100%;
                min-height: 2.5em;
                margin-right: 1.125em;
              }

              .header-phones-container > div {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: stretch;
                height: 100%;
              }
                .header-phones {
                  font-size: 1.875em;
                  font-weight: 700;
                  line-height: 1.3;
                  white-space: nowrap;
                  cursor: default;
                  padding-bottom: 0.3333em;
                }
                  .header-phones a {
                    cursor: default;
                    white-space: nowrap;
                  }

                .header-phones-notice-container {
                  font-size: 1.125em;
                  font-weight: 600;
                  padding-bottom: 0.1666em;
                }
                  .header-phones-notice-container,
                  .header-phones-notice-container * {
                    text-align: center;
                  }

                .header-callback-link {
                  font-size: 1.125em;
                  font-weight: 600;
                  padding-bottom: 0.4444em;
                  margin-top: 0.2222em;
                  text-align: center;
                }
                  .header-callback-link a {
                    display: inline-block;
                    border-bottom: 1px dashed #333;
                  }
                    .header-callback-link a:hover {
                      border-bottom-color: transparent;
                    }


      .header-menu-container {
        background: #e3925e;
        background: -moz-linear-gradient(top, #ee9d69, #bc6a36);
        background: -webkit-gradient(linear, left top, left bottom,color-stop(0%,#ee9d69),color-stop(100%,#bc6a36));
        background: -webkit-linear-gradient(top, #ee9d69, #bc6a36);
        background: -o-linear-gradient(top, #ee9d69, #bc6a36);
        background: -ms-linear-gradient(top, #ee9d69, #bc6a36);
        background: linear-gradient(top, #ee9d69, #bc6a36);
      }
        .header-menu {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          position: relative;
          z-index: 1;
        }
          .header-menu li,
          .header-menu li > div {
            position: relative;
          }
            .header-menu .dropmarker {
              display: none;
            }

            .header-menu li > div a {
              cursor: pointer;
            }
              .header-menu li.active > div a {
                cursor: default;
              }

            .header-menu li > div > div > a,
            .header-menu li > div > div > span {
              display: block;
              white-space: nowrap;
            }

            .header-menu > li.with-dropdown > div:after {
              content: "";
              background: #c47847;
              display: block;
              position: absolute;
              bottom: -4px;
              left: 0px;
              right: 0px;
              height: 4px;

              visibility: hidden;
              opacity: 0;

              -webkit-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -moz-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -o-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              transition: opacity .3s ease 0s, visibility 0s ease .3s;
            }
              .header-menu > li.with-dropdown:hover > div:after {
                visibility: visible;
                opacity: 1;

                -webkit-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -moz-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -o-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                transition: opacity .3s ease 0s, visibility 0s ease 0s;
              }

            .header-menu > li > div > div > a,
            .header-menu > li > div > div > span {
              color: #fff;
              font-size: 1.25em;
              font-weight: 500;
              padding: 1.1em 0em;
              text-align: center;
              text-decoration: none;
            }
              .header-menu > li > div > div > a:hover > span {
                text-decoration: underline;
              }


            .header-menu > li > ul {
              background: #fff;
              border: 4px solid #c47847;
              position: absolute;
              top: 100%;
              left: 0px;
              min-width: 100%;
              padding: 0.625em 0.9375em;
              margin-top: 4px;

              visibility: hidden;
              opacity: 0;

              -webkit-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -moz-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              -o-transition: opacity .3s ease 0s, visibility 0s ease .3s;
              transition: opacity .3s ease 0s, visibility 0s ease .3s;
            }
              .header-menu > li:hover > ul {
                visibility: visible;
                opacity: 1;

                -webkit-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -moz-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                -o-transition: opacity .3s ease 0s, visibility 0s ease 0s;
                transition: opacity .3s ease 0s, visibility 0s ease 0s;
              }


              .header-menu > li li > div {
                padding: 0.1875em 0em 0em;
              }
                .header-menu > li li > div > div > a {
                  font-weight: 400;
                }
                  .header-menu > li li > div > div > * > span {
                    text-decoration: none;
                  }
                    .header-menu > li li > div > div > a:hover > span {
                      color: #c47847;
                      text-decoration: underline;
                    }
                    .header-menu > li li.active > div > div > a > span {
                      color: #c47847;
                      font-weight: 600;
                      text-decoration: none;
                    }


              .header-menu > li.with-groups > ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 0px;
                padding-left: 0px;
                padding-right: 0px;
                overflow: auto;
              }
                .header-menu > li.with-groups > ul > li {
                  padding: 0.625em 0.9375em 0em 0.9375em;
                }
                  .header-menu > li.with-groups > ul > li > div {
                    font-size: 1.125em;
                    font-weight: 700;
                    line-height: 1.5;
                    padding: 0em 0em 0.1666em;
                  }
                    .header-menu > li.with-groups > ul > li > div > div > span > span {
                      text-transform: uppercase;
                    }


      .header-discount {
        color: #c47847;
        font-weight: 600;
        text-align: center;
        border: solid #c47847;
        border-width: 0px 0px 2px;
        padding: 1em 0em 0.9375em;
      }
        .header-discount a {
          color: #c47847;
          text-decoration: underline;
          cursor: pointer;
        }
          .header-discount a:hover {
            text-decoration: none;
          }
  /* Header end */



  /* Content block */
  .content {
    padding: 1.25em 0em 0em;
    position: relative;
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .main-rotator-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      padding: 0em 0em 1.875em;
      position: relative;
      z-index: 0;
    }
      .main-rotator-wrapper {
        width: 100%;
        max-height: 400px;
        position: relative;
        overflow: hidden;
        order: 2;
      }
        .main-rotator-wrapper:before {
          content: "";
          display: block;
          height: 0px;
          padding: 400px 0px 0px;
        }
          .main-rotator-wrapper.slick-initialized:before {
            display: none;
          }

          .main-rotator-slide {
            position: relative;
            z-index: 1;
          }
            .main-rotator-wrapper > .main-rotator-slide {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
            }
              .main-rotator-wrapper > .main-rotator-slide:first-child {
                z-index: 2;
              }

            .main-rotator-slide-image {
              background-color: #e0e0e0;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: cover;
              max-height: 400px;
              position: relative;
              z-index: 1;
            }
              .main-rotator-slide-image:before {
                content: "";
                display: block;
                font-size: 0px;
                height: 0px;
                padding: 400px 0px 0px;
              }

              .main-rotator-slide-image a {
                display: block;
                color: transparent;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }

            .main-slide-code-container {
              color: #fff;
              position: absolute;
              left: 0px;
              right: 0px;
              bottom: 0px;
              max-height: 70%;
              padding: 5em 2.25em 2.125em;
              overflow: hidden;
              z-index: 2;
            }
              .main-slide-code-container:before {
                content: "";
                background: url('/i/black-gradient-bottom-top.svg') no-repeat 50% 100%/auto 100% transparent;
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                opacity: 0.7;
                z-index: 1;
              }

              .main-slide-code-container > div {
                z-index: 2;
                position: relative;
              }
              .main-slide-code-container .slide-title {
                font-size: 1.5em;
                font-weight: 700;
              }
                .main-slide-code-container .slide-title a {
                  color: #fff;
                  text-decoration: underline;
                  cursor: pointer;
                }
                  .main-slide-code-container .slide-title a:hover,
                  .main-rotator-slide-image:hover + .main-slide-code-container .slide-title a {
                    text-decoration: none;
                  }

              .main-slide-code-container .slide-title + div {
                padding-top: 0.75em;
              }


          .main-rotator-arrows {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #f0f0f0;
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 1;
          }
            .main-rotator-arrows.with-arrows:before {
              content: "";
              background: #333;
              width: 1px;
              height: 1.25em;
              order: 2;
            }

            .main-rotator-arrows > div {
              background: url('/i/arrow-01-left.svg') no-repeat 50% 50% transparent;
              background-size: auto 1.375em;
              width: 3.75em;
              height: 3.75em;
              cursor: pointer;
            }
              .main-rotator-arrows > div.slick-prev {
                order: 1;
              }
              .main-rotator-arrows > div.slick-next {
                background-image: url('/i/arrow-02-right.svg');
                order: 3;
              }


      .main-rotator-left-container {
        box-sizing: content-box;
        width: 240px;
        min-width: 240px;
        max-height: 400px;
        padding-right: 1.25em;
        order: 1;
      }
      .main-rotator-right-container {
        box-sizing: content-box;
        width: 250px;
        min-width: 250px;
        max-height: 400px;
        padding-left: 1.25em;
        order: 3;
      }
        .with-right-2 .main-rotator-right-container {
          max-height: 190px;
        }

        .main-rotator-left-container > div,
        .main-rotator-right-container > div {
          box-sizing: content-box;
          height: 100%;
          position: relative;
          overflow: hidden;
        }
        .main-rotator-right-container > div + div {
          padding-top: 1.25em;
        }

          .main-rotator-aside-image {
            background-color: #e0e0e0;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            height: 100%;
            position: relative;
            z-index: 1;
          }

            .main-rotator-aside-image a {
              display: block;
              color: transparent;
              font-size: 0px;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 1;
            }

          .main-rotator-aside-code {
            color: #fff;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            max-height: 90%;
            overflow: hidden;
            z-index: 2;
          }
            .main-rotator-left-container .main-rotator-aside-code {
              top: 0px;
              bottom: auto;
              padding: 1.625em 0.25em 4.5em 1.75em;
            }
            .main-rotator-right-container .main-rotator-aside-code {
              padding: 1.75em 0.25em 1.125em 1.25em;
            }

            .main-rotator-aside-code:before {
              content: "";
              background: url('/i/black-gradient-bottom-top.svg') no-repeat 50% 100%/auto 100% transparent;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              opacity: 0.7;
              z-index: 1;
            }
              .main-rotator-left-container .main-rotator-aside-code:before {
                background: url('/i/black-gradient-top-bottom.svg') no-repeat 50% 0%/auto 100% transparent;
              }

            .main-rotator-aside-code > div {
              z-index: 2;
              position: relative;
            }
            .main-rotator-aside-code .slide-title {
              font-size: 1.125em;
              font-weight: 600;
            }
              .main-rotator-aside-code .slide-title a {
                color: #fff;
                text-decoration: underline;
                cursor: pointer;
              }
                .main-rotator-aside-code .slide-title a:hover,
                .main-rotator-aside-image:hover + .main-rotator-aside-code .slide-title a {
                  text-decoration: none;
                }

            .main-rotator-aside-code .slide-title + div {
              padding-top: 0.7222em;
            }


    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      font-size: 0.875em;
      line-height: 1.2;
      padding: 0em 0em 1em;
      position: relative;
    }
      .breadcrumbs li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          vertical-align: baseline;
        }
        .breadcrumbs li:after {
          content: "/";
          padding: 0em 0.5em;
        }
          .breadcrumbs li:last-child:after {
            display: none;
          }

        .breadcrumbs a {
          color: #c47847;
          cursor: pointer;
          text-decoration: none;
        }
          .breadcrumbs a:hover {
            text-decoration: underline;
          }

        .breadcrumbs span {
          font-weight: 600;
        }


    .subheader-container,
    .subheader,
    .subsubheader {
      position: relative;
      z-index: 2;
    }
      .subheader-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        line-height: 1.1;
        padding: 0.5em 0em 2.25em;
      }
        .subheader-container > div:first-child {
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: contain;
          background-color: transparent;
          width: 3.75em;
          min-width: 3.75em;
          height: 3.75em;
        }
          .subheader-container > div.subheader-stars {
            background-image: url('/i/subheader-icon-stars.svg');
          }
          .subheader-container > div.subheader-images {
            background-image: url('/i/subheader-icon-images.svg');
          }
          .subheader-container > div.subheader-price {
            background-image: url('/i/subheader-icon-price.svg');
          }
          .subheader-container > div.subheader-roller {
            background-image: url('/i/subheader-icon-roller.svg');
          }

        .subheader-container > div + div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          padding-left: 1.5em;
        }
          .subheader-slogan {
            padding: 0.375em 0em;
          }

      .subheader {
        font-size: 1.75em;
        font-weight: 900;
        line-height: 1.3;
        text-transform: uppercase;
        padding: 0.25em 0em 1em;
      }
        h1.subheader {
          font-size: 2em;
          padding-bottom: 0.9375em;
        }
        .subheader-container .subheader {
          line-height: 1.2;
          padding: 0em;
        }

      .subsubheader {
        font-size: 1.875em;
        font-weight: 700;
        line-height: 1.1;
        padding: 0.5em 0em 0.7em;
      }
        .subsubheader > * {
          display: block;
          color: #e3925e;
          background: -webkit-linear-gradient(top, #ee9d69, #bc6a36);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          vertical-align: baseline;
        }
          .subsubheader > * > span {
            font-size: 1.6em;
            font-weight: 900;
            vertical-align: baseline;
          }


    .text-container {
      padding: 0em 0em 1.875em;
      position: relative;
      z-index: 1;
    }


    .quick-link-list {
      font-size: 1.125em;
      border-width: 1px 0px 1px;
      border-style: solid;
      border-color: #eaeaea;
      margin: 0em 0em 1.666em;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: center;
        padding: 1em 0em;
        margin: -1em calc(-1.5em - 1px) 0em 0em /*0em 0em 0em -1.2222em*/;

      }
        .quick-link-list > div > div {
          border-right: 1px solid #999;
          padding: 0.25em 0.75em 0.25em 0em;
          margin: 1em 0.75em 0em 0em;
        }
          .quick-link-list > div > div:last-child {
            border-right-color: transparent;
          }

          .quick-link-list a {
            color: #333;
            display: inline-block;
            font-weight: 400;
            line-height: 1.1;
            vertical-align: middle;
            text-decoration: none;
            border-bottom: 1px dashed #333;
            cursor: pointer;
          }
            .quick-link-list a:hover,
            .quick-link-list .active a,
            .quick-link-list a.active {
              border-bottom-color: transparent;
            }
            .quick-link-list .active a,
            .quick-link-list a.active {
              color: #c47847;
              font-weight: 700;
              cursor: default;
            }


    .incut {
      margin: 0em 0em 1.875em;
    }
      .incut > div {
        background: #f0f0f0;
        border-left: 0.5em solid #c47847;
        padding: 1.375em;
      }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0em 0em 1.875em;
      margin: -1.375em 0px 0px -1.375em;
      position: relative;
    }
      .gallery-item {
        padding: 1.375em 0px 0px 1.375em;
        position: relative;
      }

      .gallery-notice {
        padding: 0em 0em 1.875em !important;
        margin: -0.625em 0px 0px !important;
      }


    .list-view,
    .portfolio-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;

      margin: -1.75em 0em 0em -2.875em;
      position: relative;
    }
    .list-view {
      justify-content: center;
      padding: 0em 0em 1.875em;
    }
      .list-view-item {
        width: 33.333333%;
        padding: 1.75em 0em 0em 2.875em;
        position: relative;
      }
        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          width: 100%;
          position: relative;
        }
          .list-view-item-image {
            font-size: 0px;
            background: #eaeaea;
            position: relative;
            order: 2;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              font-size: 0px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: cover;
              height: 0px;
              padding: 56.994818% 0% 0%;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-name {
            font-size: 1.25em;
            font-weight: 700;
            line-height: 1.1;
            text-decoration: none;
            margin: 0em 0em 0.6868em;
            position: relative;
            order: 1;
          }
            .list-view-item-name a {
              display: inline-block;
              color: #e3925e;
              background: -webkit-linear-gradient(top, #ee9d69, #bc6a36);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              /*border-bottom: 2px solid #bc6a36;*/
              cursor: pointer;
            }
              /*.list-view-item-name a:hover,
              .list-view-item-image:hover + .list-view-item-name a,
              .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                border-bottom-color: transparent;
              }*/

          .list-view-item-notice {
            margin: 1em 0em 0em;
            order: 3;
          }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    /*.list-block-container + .list-page-group-name,*/
    .faq-list + .list-page-group-name {
      padding-top: 0.8em;
    }
    /*.list-page-group-name ~ .list-block-container + .list-block-container,*/
    .list-page-group-name ~ .faq-list + .faq-list {
      padding-top: 1.25em;
    }


    .content-block-upper {
      position: relative;
    }
      .content-block-upper:before {
        content: "";
        background: url('/i/block-01-upper-bg.jpg') no-repeat 100% 0%/contain transparent;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: -5em;
        height: 100%;
        max-height: 1140px;
        z-index: 0;
      }

      .content-block-upper > div {
        position: relative;
        z-index: 1;
      }
        .image-info-text-block {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          padding: 0em 0em 1.875em;
        }
          .image-info-text-block > span:first-child {
            content: "";
            background: url('/f/upload/image-info-text-bg.jpg') no-repeat 50% 50%/cover transparent;
            max-width: 0px;
            min-width: 33.6%;
          }

          .image-info-text-block > div {
            width: 100%;
            padding-left: 2.5em;
          }


        .list-view-float-text-block {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
          .list-view-float-text-block > div:first-child {
            width: 100%;
          }

          .list-view-float-text-block > div + div {
            width: 36%;
            min-width: 36%;
          }
            .list-view-float-text-block .list-view {
              margin-left: 0em;
            }
              .list-view-float-text-block .list-view-item {
                width: 100%;
              }
                .list-view-float-text-block .list-view-item-name {
                  font-size: 1.5em;
                  margin-bottom: 0.625em;
                }


    .content-block-ruler,
    .content-block-blue {
      color: #fff;
      padding: 0em 0em 1.875em;
      position: relative;
    }
      .content-block-ruler {
        padding-top: 1.25em;
      }
      .content-block-ruler > div,
      .content-block-blue > div {
        background: #222e37;
        position: relative;
      }
      .content-block-blue > div {
        padding: 2em 0em 0.625em;
      }
        .content-block-ruler > div:before,
        .content-block-blue > div:before {
          content: "";
          background: url('/i/block-02-blue-bg.jpg') no-repeat 50% 0%/cover transparent;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          max-width: 1920px;
          height: 100%;
          max-height: 555px;
          margin: 0px auto;
          z-index: 0;
        }

        .content-block-ruler > div > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          position: relative;
          z-index: 1;
        }
          .content-block-ruler > div > div:before {
            content: "";
            background: url('/i/ruler.png') no-repeat 50% 0%/contain transparent;
            width: 34.56%;
            min-width: 34.56%;
            margin-top: -0.9375em;
          }
            .content-block-ruler > div > div > div {
              padding: 2.25em 0em 2.25em 4.125em;
            }
              .content-block-ruler > div > div > div a {
                color: #fff;
              }


    .content-block-plan {
      position: relative;
    }
      .content-block-plan:before {
        content: "";
        background: url('/i/block-03-plan-bg.jpg') no-repeat 100% 0%/contain transparent;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: -5em;
        height: 100%;
        max-height: 570px;
        z-index: 0;
      }

      .content-block-plan > div {
        position: relative;
        z-index: 1;
      }


    .content-block-blue-2 {
      color: #fff;
      padding: 0em 0em 1.875em;
      position: relative;
    }
      .content-block-blue-2 > div {
        background: #16232c;
        position: relative;
        padding: 2em 0em 0.625em;
      }
        .content-block-blue-2 > div:before {
          content: "";
          background: url('/i/block-04-blue-2-bg.jpg') no-repeat 50% 0%/cover transparent;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          max-width: 1920px;
          height: 100%;
          max-height: 1200px;
          margin: 0px auto;
          z-index: 0;
        }


    .content-block-worker {
      padding: 0em 0em 0.625em;
      position: relative;
    }
      .content-block-worker:before {
        content: "";
        background: url('/i/block-05-worker-bg.jpg') no-repeat 100% 0%/contain transparent;
        display: block;
        position: absolute;
        top: 0px;
        right: -5em;
        width: 70%;
        height: 100%;
        max-height: 695px;
        z-index: 0;
      }

      .content-block-worker > div {
        position: relative;
        z-index: 1;
      }


    .content-callback-title {
      font-size: 1.375em;
      font-weight: 600;
      vertical-align: baseline;
    }
      .content-callback-title > span {
        font-size: 1.3636em;
        font-weight: 900;
        text-transform: uppercase;
        vertical-align: baseline;
      }

    .content-callback-text-container {
      padding: 1.5625em 0em 0em 4.375em;
    }
      .content-callback-text {
        line-height: 1.4;
      }
        .content-callback-button {
          padding: 1.75em 0em 0em;
        }


    .features-block-container .list-view {
      margin-top: -2.5em;
    }
      .features-block-container .list-view-item {
        padding-top: 2.5em;
      }
        .features-block-container .list-view-item-name {
          font-size: 1.25em;
          line-height: 1.3;
        }
        .features-block-container .list-view-item-notice {
          margin: 0em;
        }


    .gallery-rotator-block-container .list-view-item {
      width: 50%;
    }
    .gallery-rotator-block-container.gallery-items-width-3 .list-view-item {
      width: 33.3333%;
    }
    .gallery-rotator-block-container.gallery-items-width-4 .list-view-item {
      width: 25%;
    }
      .gallery-rotator-block-container .list-view-item-image {
        background: transparent;
        order: 2;
      }
        .gallery-rotator {
          position: relative;
        }
          .gallery-rotator:before {
            content: "";
            background: #eaeaea;
            display: block;
            height: 0px;
            padding: 61.4% 0% 0%;
          }
            .gallery-rotator.slick-initialized:before {
              display: none;
            }

          .gallery-rotator-image {
            position: relative;
            z-index: 0;
          }
            .gallery-rotator > .gallery-rotator-image {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
            }
              .gallery-rotator > .gallery-rotator-image:first-child {
                z-index: 1;
              }

              .gallery-rotator-image a {
                width: 100%;
              }

        .gallery-rotator-pager {
          position: relative;
          overflow: hidden;
        }
          .gallery-rotator-pager > div {
            display: none;
            padding: 16px 0px 0px;
            margin: -7px 0px 0px -7px;
          }
            .gallery-rotator-pager > div.slick-initialized {
              display: block;
            }

            .gallery-rotator-block-container.gallery-items-width-3 .list-view-item .gallery-rotator-pager > div {
              margin-left: -6px;
            }
            .gallery-rotator-block-container.gallery-items-width-3 .list-view-item .gallery-rotator-pager > div {
              margin-left: -6px;
            }

            .gallery-rotator-pager .slick-track {
              margin: 0px;
            }

            .gallery-rotator-pager-image {
              display: inline-block;
              width: 14.2857%;
              padding: 7px 0px 0px 7px;
              z-index: 0;
            }
              .gallery-rotator-pager-image div {
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                height: 0px;
                padding: 60% 0% 0%;
                cursor: pointer;
                position: relative;
              }
                .gallery-rotator-pager-image div:after {
                  content: "";
                  border: 2px solid transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 0;

                  -webkit-transition: border-color .3s ease 0s;
                  -moz-transition: border-color .3s ease 0s;
                  -o-transition: border-color .3s ease 0s;
                  transition: border-color .3s ease 0s;
                }
                  .gallery-rotator-pager-image.slick-current div:after {
                    border-color: #c47847;
                  }

      .gallery-rotator-block-container .list-view-item-name {
        font-size: 1.5em;
      }
        .gallery-rotator-block-container.gallery-items-width-3 .list-view-item-name {
          font-size: 1.375em;
        }
        .gallery-rotator-block-container.gallery-items-width-4 .list-view-item-name {
          font-size: 1.125em;
        }


    .work-steps-block-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      padding: 0em 0em 1.875em;
      margin-left: -1.25em;
      counter-reset: work-step-counter;
    }
      .work-steps-block-container > div {
        counter-increment: work-step-counter;
        width: 20%;
        padding-left: 1.25em;
      }
        .work-steps-block-container > div > div {
          border: 4px solid #c47847;
          height: 100%;
          padding: 0.75em 1em 1em;
        }
          .work-steps-block-container > div > div:before {
            content: counter(work-step-counter, decimal-leading-zero);
            display: block;
            color: #999;
            font-size: 4em;
            font-weight: 900;
            line-height: 1;
            letter-spacing: 3px;
            padding-bottom: 0.375em;
          }

          .work-steps-block-container > div > div > div {
            font-size: 0.875em;
            line-height: 1.5;
            text-transform: uppercase;
          }


    .portfolio-info-icons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: -0.375em 0em 0em -1.875em;
    }
      .portfolio-info-icons > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
        padding: 0.375em 0em 0em 1.875em;
      }
        .portfolio-info-icons .price-item > span {
          border-bottom: 1px dashed #333;
          cursor: pointer;
        }
          .portfolio-info-icons .price-item > span:hover {
            border-bottom-color: transparent;
          }

        .list-view .portfolio-info-icons > div {
          width: 100%;
        }

        .portfolio-info-icons img {
          height: 1.5em;
          margin-right: 0.8125em;
        }

      .portfolio-info-icons + div {
        padding: 1em 0em 0em;
      }


    .portfolio-block-container .list-view {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      margin: 0px;
    }
      .portfolio-block-container .list-view-item-wrapper {
        height: 100%;
      }
        .portfolio-block-container .list-view-item-notice {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          height: 100%;
        }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0em 0em 1.875em;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        border-bottom: 1px solid #e8e8e8;
      }
      .price-table > div + div {
        margin-top: 2.1875em;
      }
        .price-table > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
        .price-table-group {
          justify-content: flex-start !important;
          padding: 0 0 0.875em 30px;
        }
          .price-table-counter .price-table-group {
            counter-reset: price-table-subheader-counter;
          }
          .price-table-group span {
            font-size: 1.25em;
            font-weight: 700;
            text-transform: uppercase;
          }
            .price-table-counter .price-table-group span:before {
              content: counter(price-table-group-counter) ". ";
              counter-increment: price-table-group-counter;
              /*color: #333;*/
            }

        .price-table-head {
          color: #fff;
          background: #e3925e;
          background: -moz-linear-gradient(top, #ee9d69, #bc6a36);
          background: -webkit-gradient(linear, left top, left bottom,color-stop(0%,#ee9d69),color-stop(100%,#bc6a36));
          background: -webkit-linear-gradient(top, #ee9d69, #bc6a36);
          background: -o-linear-gradient(top, #ee9d69, #bc6a36);
          background: -ms-linear-gradient(top, #ee9d69, #bc6a36);
          background: linear-gradient(top, #ee9d69, #bc6a36);
          align-items: flex-end !important;
        }

        .price-table-subheader {
          background: #fff;
          position: relative;
          z-index: 1;
        }

        .price-table-row {
          background: #fff;
        }
          .price-table-row.odd,
          .price-table-design .price-table-row:nth-child(odd) {
            background: #f7f7f7;
          }
          .price-name,
          .price-price,
          .price-unit {
            font-weight: 400;
            padding: 0.3125em 0.75em;
            flex-basis: 100%;
            flex-shrink: 4;
          }
          .price-name {
            padding-left: 30px;
          }
          .price-price,
          .price-unit {
            text-align: center;
            flex-shrink: 8;
          }
            .price-price > span {
              font-size: inherit;
              width: 0px;
              height: 0px;
              padding: 0em 0.15em 0em;
            }

            .price-table-head .price-name,
            .price-table-head .price-price,
            .price-table-head .price-unit {
              font-size: 1.25em;
              font-weight: 500;
              line-height: 1.2;
              padding-top: 0.5em;
              padding-bottom: 0.5em;
            }
            .price-table-subheader .price-name {
              /*color: #333;*/
              font-weight: 600;
              text-transform: uppercase;
              padding-top: 0.625em;
              padding-bottom: 0.5em;
              border-bottom: 1px solid #e8e8e8;
            }
              .price-table-counter .price-table-subheader .price-name:before {
                content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
                counter-increment: price-table-subheader-counter;
                font-weight: 500;
              }
              .price-table-row + .price-table-subheader .price-name {
                border-top: 1px solid #e8e8e8;
              }

    .price-table-design .price-table-row {
      align-items: center;
    }
      .price-table-design .price-name {
        justify-content: flex-start;
        flex-shrink: 7;
      }
      .price-table-design .price-price,
      .price-table-design .price-unit {
        justify-content: center;
        flex-shrink: 9;
      }


    .plus {
      display: inline-block;
      color: transparent;
      background: url('/i/plus-icon.svg') no-repeat 50% 50% transparent;
      width: 1.25em;
      height: 1.25em;
      margin: 0.1875em;
      vertical-align: baseline;
    }


    .price-groupped-table-container .quick-link-list {
      border: none;
    }
      .price-groupped-table-container .quick-link-list > div {
        font-size: 1em;
        padding: 0em;
      }
        .price-groupped-table-container .quick-link-list > div > div {
          font-size: 0.875em;
        }
          .price-groupped-table-container .quick-link-list:not(.selected) > div > div:first-child a {
            color: #c47847;
            border-bottom-color: transparent;
            font-weight: 600;
            cursor: default;
          }

    .price-groupped-table-container .price-table:not(.selected) > div:not(:first-child),
    .price-groupped-table-container .price-table.selected > div:not(.active),
    .price-groupped-table-container .price-table-group {
      display: none;
    }
      .price-groupped-table-container .price-table > div + div {
        margin-top: 0px;
      }


    .advantages-block-container .list-view {
      margin-top: -2.25em;
    }
      .advantages-block-container .list-view-item {
        padding-top: 2.25em;
      }
        .advantages-block-container .list-view-item-image {
          background: transparent;
        }
          .advantages-block-container .list-view-item-image span {
            background-position: 50% 0%;
            background-size: contain;
            padding: 20% 0% 0%;
          }

        .advantages-block-container .list-view-item-notice {
          font-weight: 400;
          line-height: 1.5;
          text-align: center;
        }



    .discount-block-container div + .discount-item-button {
      padding: 1em 0em 0em;
    }
      .discount-item-button .button-style {
        font-size: 0.875em;
        box-shadow: 0em 0em 1.1em -0.15em #c47847;
        padding: 1em 1.5em;
      }



  .response-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: stretch;
    padding: 0em 0em 1em;
    margin: -1.25em 0em 0em;
    z-index: 1;
  }
    .response-item {
      width: 50%;
      padding: 1.25em 1em;
    }
      .response-list.one-item .response-item {
        width: 100% !important;
      }

      .response-item > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        background: #f0f0f0;
        border-radius: 5px;
        height: 100%;
        padding: 1.25em 1.5625em 1.125em 1.25em;
      }
        .response-item > div > div:first-child {
          font-size: 0.875em;
          text-align: justify;
          line-height: 1.4;
          position: relative;
        }
          .response-item > div > div.more {
            max-height: 90px;
            overflow: hidden;
            cursor: pointer;
          }
          .response-item > div > div.is-opened {
            max-height: unset !important;
            padding: 0em 0em 2.14285714em;
            overflow: unset !important;
          }

          .response-item > div > div.more:before {
            content: "";
            display: block;
            background: url('/i/gray-gradient-bottom-top.svg') repeat-x 50% 50%/auto 100% transparent;
            border-bottom: 2.14285714em solid #f0f0f0;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 30px;
            z-index: 1;
          }
            .response-item > div > div.is-opened:before {
              visibility: hidden;
            }

          .response-item > div > div.more:after {
            content: "показать полностью";
            display: block;
            color: #c47847;
            line-height: 1;
            border-bottom: 1px dashed #c47847;
            width: min-content;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0.42857143em;
            margin: 0px auto;
            white-space: nowrap;
            z-index: 2;
          }
            .response-item > div > div.more:hover:after {
              border-bottom-color: transparent;
            }

            .response-item > div > div.is-opened:after {
              content: "свернуть";
            }

        .response-item > div > div:first-child + div {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: baseline;
          color: #505050;
          padding: 10px 0px 0px;
        }
          .response-item > div > div:first-child + div > div:first-child {
            font-weight: 600;
          }
          .response-item > div > div:first-child + div > div + div {
            font-size: 0.875em;
            padding-left: 15px;
          }

  .response-list + .text-container > .form-block-container {
    max-width: 800px;
    margin: 0px auto;
  }
    .response-list + .text-container > .form-block-container .input-container textarea {
      height: 92px;
    }



  .team-member-block-container .list-view {
    justify-content: flex-start;
  }
    .team-member-block-container .list-view-item {
      width: 20%;
    }
      .team-member-block-container .list-view-item-image {
        order: 1;
      }
        .team-member-block-container .list-view-item-image > span {
          padding-top: 90%;
        }

      .team-member-block-container .list-view-item-text {
        order: 2;
      }
        .team-member-block-container .list-view-item-name {
          font-size: 1em;
          margin: 0.75em 0em 0em;
        }

        .team-member-block-container .list-view-item-notice {
          font-size: 0.875em;
          margin: 0.75em 0em 0.625em;
        }





  .faq-list {
    padding: 1px 0em 1.875em;
  }
    .faq-list > div {
      border: solid #e8e8e8;
      border-width: 1px 0px;
      padding: 1em 1.25em;
      margin: -1px 0px 0px;
      position: relative;
      cursor: pointer;
    }
      .faq-list > div > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 1.1;
        text-decoration: none;
        padding: 1em 1.25em;
        margin: -1em -1.25em;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        z-index: 1;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
        .faq-list > div > div:first-child > span:first-child {
          display: inline-block;
          font-size: 1.125em;
          color: #e3925e;
          background: -webkit-linear-gradient(top, #333, #333);
          background: -moz-linear-gradient(top, #333, #333);
          background: -ms-linear-gradient(top, #333, #333);
          background: -o-linear-gradient(top, #333, #333);
          background: linear-gradient(to bottom, #333, #333);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
          .faq-list > div:hover > div:first-child > span:first-child,
          .faq-list > div.active > div:first-child > span:first-child {
            background: -webkit-linear-gradient(top, #ee9d69, #bc6a36);
            background: -moz-linear-gradient(top, #ee9d69, #bc6a36);
            background: -ms-linear-gradient(top, #ee9d69, #bc6a36);
            background: -o-linear-gradient(top, #ee9d69, #bc6a36);
            background: linear-gradient(to bottom, #ee9d69, #bc6a36);
            -webkit-background-clip: text;
            background-clip: text;
          }
          .faq-list > div.active > div:first-child > span:first-child {
            font-weight: 700;
          }

        .faq-list > div > div:first-child > span + span {
          content: "";
          display: flex;
          background: url('/i/plus-icon.svg') no-repeat 50% 50%/1.25em auto transparent;
          width: 1.25em;
          min-width: 1.25em;
          height: 1.25em;
          line-height: 1;
          margin: 0em 0em 0em 1.25em;
          position: relative;
          z-index: 1;

          -webkit-transition: background-image 0.2s ease 0s;
          -moz-transition: background-image 0.2s ease 0s;
          -o-transition: background-image 0.2s ease 0s;
          transition: background-image 0.2s ease 0s;
        }
          .faq-list > div.active > div:first-child > span + span {
            background-image: url('/i/minus-icon.svg');
          }


        .faq-list > div > div:first-child + div {
          display: none;
          line-height: 1.3;
          overflow: hidden;
        }
          .faq-list > div > div:first-child + div > div {
            border-left: 4px solid #c47847;
            padding: 0em 2.5em 0em 1.125em;
            margin: 1.25em 0em 0em 0em;
          }
            .faq-list > div > div:first-child + div > div p:last-child {
              margin-bottom: 0px;
            }
  /* Content block end */



  /* Contact page */
  .contact-label {
    font-size: 1.125em;
    font-weight: 600;
  }
    .contact-label + div {
      padding: 0.3125em 0em 0em 1em;
    }

  .contact-top-panel,
  .contact-info-container,
  .contact-map-block-container {
    margin: 0em 0em 1.875em;
  }

  .contact-top-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
    .contact-phones > div,
    .contact-emails > div {
      vertical-align: baseline;
    }
      .contact-phones a,
      .contact-emails a {
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        cursor: default;
      }
      .contact-phones a {
        font-size: 1.375em;
      }
      .contact-emails a {
        cursor: pointer;
      }
        .contact-emails a:hover {
          text-decoration: underline;
        }

    .contact-callback {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
      .contact-callback .button-style {
        font-size: 1.125em;
      }


  .contact-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
    .contact-info-container > div {
      width: 50%;
    }
      .contact-info-wrapper > .subsubheader {
        font-size: 1.375em;
        padding: 0em;
      }
      .contact-info-wrapper > div + div {
        padding: 0.9375em 0em 0em;
      }


  .contact-map-block-container {
    background: #eaeaea;
    position: relative;
  }
    .contact-map-block-container:before {
      content: "";
      display: block;
      height: 0px;
      padding-top: 30%;
      z-index: 0;
    }

    .contact-map-block-container:after {
      content: "Загрузка карты...";
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1;
    }

    .contact-map-block-container > div {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 2;
    }
  /* Contact page end */



  /* Footer */
  .footer {
    background: #f0f0f0;
    position: relative;
    z-index: 1;
  }
    .footer > .wrapper > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      margin: 0em 0em 0em -2.5em;
    }
      .footer > .wrapper > div > div {
        flex-grow: 1;
      }

      .footer-menu-container > ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
        .footer-menu-container > ul > li {
          flex-grow: 1;
          padding: 2.5em 0em 2.5em 2.5em;
        }
          .footer-menu-container li > div {
            padding: 0.375em 0em 0em;
          }
            .footer-menu-container li > div > div a {
              font-weight: 400;
              cursor: pointer;
              text-decoration: none;
            }
              .footer-menu-container li > div > div a:hover {
                text-decoration: underline;
              }
              .footer-menu-container li.active > div > div a {
                text-decoration: underline;
                cursor: default;
              }
                .footer-menu-container > ul > li > ul > li.active > div > div a {
                  color: #c47847;
                  font-weight: 600;
                }

          .footer-menu-container > ul > li > div {
            padding: 0em 0em 0.4375em;
          }
            .footer-menu-container > ul > li > div > div a,
            .footer-menu-container > ul > li > div > div span {
              font-size: 1.25em;
              font-weight: 900;
              line-height: 1.2;
              text-transform: uppercase;
              letter-spacing: 1px;
            }


      .footer-contact-container {
        color: #fff;
        padding: 0em 0em 0em 2.5em;
        position: relative;
      }
        .footer-contact-container:before {
          content: "";
          background: #16232c;
          display: block;
          position: absolute;
          top: 0px;
          left: 2.5em;
          right: -9999px;
          bottom: 0px;
          z-index: 0;
        }

        .footer-contact-container > div {
          padding: 0.625em 0em 2.5em 2.5em;
          position: relative;
          z-index: 1;
        }
          .footer-contact-container > div > div {
            padding-top: 1.875em;
          }
            .footer-contact-label {
              font-weight: 400;
            }
            .footer-contact-label + div {
              padding: 0.3125em 0em 0em;
            }

            .footer-address-container .footer-contact-label + div {
              font-size: 1.25em;
              font-weight: 600;
            }

            .footer-phones > div {
              vertical-align: baseline;
            }

            .footer-phones-notice-container {
              font-weight: 400;
              padding-top: 0.25em;
            }


            .footer-phones a,
            .footer-emails a {
              color: #fff;
              font-weight: 700;
              text-decoration: none;
              vertical-align: baseline;
              white-space: nowrap;
              cursor: default;
            }
            .footer-phones a {
              font-size: 1.75em;
            }
            .footer-emails a {
              font-size: 1.125em;
              cursor: pointer;
            }
              .footer-emails a:hover {
                text-decoration: underline;
              }


      .footer-copyrights {
        color: #fff;
        background: #16232c;
        padding: 1.25em 0em;
      }
        .footer-copyrights > .wrapper > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: -0.75em 0em 0em -0.75em;
        }
          .footer-copyrights > .wrapper > div > div {
            padding: 0.75em 0em 0em 0.75em;
          }

            .footer-social {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
            }
              .footer-social > div + div {
                padding: 0em 0em 0em 0.75em;
              }
                .footer-social a {
                  display: block;
                  color: transparent;
                  background: no-repeat 50% 50%/contain transparent;
                  border-radius: 0.375em;
                  width: 2.25em;
                  height: 2.25em;
                  overflow: hidden;
                  cursor: pointer;
                }
                  .footer-social .icon-vk a {
                    background-image: url('/i/social-icon-vk.svg');
                  }
                  .footer-social .icon-insta a {
                    background-image: url('/i/social-icon-insta-btn.svg');
                  }
                  .footer-social .icon-ok a {
                    background-image: url('/i/social-icon-ok.svg');
                  }
                  .footer-social .icon-twitter a {
                    background-image: url('/i/social-icon-twitter.svg');
                  }
                  .footer-social .icon-wa a {
                    background-image: url('/i/social-icon-wa.svg');
                  }
  /* Footer end */



  /* Other */
  .grecaptcha-badge {
    bottom: 90px !important;
    z-index: 9;
  }
/* Structure end */